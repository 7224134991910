(function () {
  'use strict';
  // jscs:disable maximumLineLength

  angular
    .module('wbAccount')
    .config(config);

  function config($translateProvider) {
    $translateProvider.translations('en', {
      'account.change': 'Edit profile',
      'account.change.submit': 'Save personal information changes',
      'account.personalData': 'Personal information',
      'account.titleId': 'Title',
      'account.titleId.1': 'Sir',
      'account.titleId.2': 'Madam',
      'account.name': 'Full name',
      'account.lastName': 'Last name',
      'account.firstName': 'First name',
      'account.contact': 'Contact',
      'account.contact.email': 'E-mail',
      'account.contact.phone': 'Phone number (optional)',
      'account.contact.fax': 'Fax (optional)',
      'account.contact.vatNumber': 'Tax Number',
      'account.save': 'Save',
      'account.updated': 'Changes saved.',
      'account.company.change': 'Edit company information',
      'account.company.updated': 'Company information saved.',
      'account.company.companyData': 'Company information',

      'account.company.company.change': 'Edit company information',
      'account.company.name': 'Company name',
      'account.company.companyNumber': 'Company number',
      'account.company.vatNumber': 'VAT number',
      'account.company.bankAccountNumber': 'Bank account number',
      'account.company.staffCount': 'Number of employees',
      'account.company.staffName': 'Employee name',
      'account.company.stolenCard.csn': 'Lost/stolen/damaged card serialnumber',
      'account.company.nameOnCard': 'Company name on card',
      'account.company.nameOnCard.tooltip': 'Name on card<br/>maximum characters: 21.',
      'account.company.contact': 'Contact',
      'account.company.contact.invoiceEmail': 'E-mail address',

      'account.company.address': 'Company address',
      'account.company.address.zipCode': 'Zip code',
      'account.company.address.city': 'City',
      'account.company.address.address': 'Address',
      'account.company.address.countryCode': 'Country',

      'account.company.invoiceAddress': 'Invoice address',
      'account.company.invoiceAddress.zipCode': 'Zip code',
      'account.company.invoiceAddress.city': 'City',
      'account.company.invoiceAddress.address': 'Address',
      'account.company.invoiceAddress.countryCode': 'Country',

      'account.company.deliveryName': 'Delivery name',
      'account.company.deliveryName.name': 'Recipients name',
      'account.company.deliveryAddress': 'Delivery address',
      'account.company.deliveryAddress.zipCode': 'Zip code',
      'account.company.deliveryAddress.city': 'City',
      'account.company.deliveryAddress.address': 'Address',
      'account.company.deliveryAddress.countryCode': 'Country',

      'account.company.owner': 'Owner’s information',
      'account.company.owner.titleId': 'Title',
      'account.company.owner.name': 'Owner’s name',
      'account.company.owner.birthName': 'Owner’s birth name',
      'account.company.owner.post': 'Owner’s title',
      'account.company.owner.nationality': 'Nationality',
      'account.company.owner.IDType': 'Type of ID',
      'account.company.owner.IDNumber': 'ID number',

      'account.IDType.1': 'ID type',
      'account.IDType.2': 'Passport',
      'account.IDType.3': 'Driving license',

      'account.company.owner.isForeign': 'Foreign citizenship',
      'account.company.owner.phone': 'Phone number (optional)',
      'account.company.owner.fax': 'Fax (optional)',
      'account.company.owner.email': 'E-mail (optional)',

      'account.company.owner.address': 'Owner’s address',
      'account.company.owner.hungarianAddress': 'Hungarian address',

      'account.company.save': 'Mentés',
      'account.newCardOrder.legend': 'You can order new cards here for your employees or partners. Please click ‘Next’ so you can order them in a few steps.',
      'account.topUp.legend': 'You can top up your cards here after ordering. Please click ‘Next’ so you can top up the cards in a few steps',
      'account.more': 'Next',

      'account.company.openAlert': 'Dear Customer!<br><br>Please note that the modified data will be updated in our systems within 24 hours. If you modify Company name / tax number, it is necessary a new contract, so please contact : 06-1 / 413-3333!<br><br>Thanks, Edenred team',

      'account.company.noCompany': 'We couldn\'t find any company for your account.<br><br>We\'re aware of this error, and our colleges will inform you about the solution via your e-mail address ({{ email }}).<br><br>Thank you for your understanding.',

      'csnFile.title': 'Download card serial number',
      'csnFile.date': 'Date',
      'csnFile.generateDate': 'Generate date',
      'csnFile.file': 'File',
      'csnFile.download': 'Download',
      'csnFile.info': 'For complete serial number list please choose "Complete Serial number list" option.',

      'completeCsnLists.title': 'Complete card token lists',
      'completeCsnLists.downloadListInfo': 'For the complete list containing card identifiers (tokens), click the download button.',
      'completeCsnLists.downloadList': 'Download Complete List',
      'completeCsnLists.card.type.1': 'Inactive',
      'completeCsnLists.card.type.2': 'Active',
      'completeCsnLists.card.type.3': 'Lost/Stolen/Damaged',
      'completeCsnLists.card.type.4': 'Blocked',
      'completeCsnLists.table.header.csn': 'Serial number',
      'completeCsnLists.table.header.nameOnCard': 'Name on card',
      'completeCsnLists.table.header.expiryDate': 'Expiry Date',
      'completeCsnLists.table.header.cardType': 'Card Type',
      'completeCsnLists.table.header.status': 'Status',
      'completeCsnLists.cardType.null.title': 'Unknown cardtype',
      'completeCsnLists.cardType.5.title': 'Edenred Shopping Card',
      'completeCsnLists.cardType.6.title': 'Edenred Schooling Card',
      'completeCsnLists.cardType.8.title': 'Rewin Standard Physical Card',
      'completeCsnLists.cardType.9.title': 'Edenred Culture and Sport Card',
      'completeCsnLists.cardType.10.title': 'Rewin Standard Virtual Card',
      'completeCsnLists.cardType.11.title': 'Edenred Motivaction Card',
      'completeCsnLists.cardType.15.title': 'Silver Card',
      'completeCsnLists.cardType.16.title': 'Gold Card',
      'completeCsnLists.cardType.17.title': 'Platinum Card',
      'completeCsnLists.cardType.28.title': 'Rewin Individual Card',
      'completeCsnLists.cardType.29.title': 'Comfort Card',
      'completeCsnLists.cardType.30.title': 'Multiwallet Card',
      'completeCsnLists.cardType.31.title': 'Edenred Kids Card',
      'completeCsnLists.cardType.32.title': 'Edenred Spouse Card',
      'completeCsnLists.cardType.33.title': 'Edenred Parent Card'
    });
  }
}());
